<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>典型案例</el-breadcrumb-item>
      <el-breadcrumb-item>案例列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区 -->
    <el-card>
      <!-- 搜索与添加区域 -->
      <!-- :gutter表示间距:span表示占据比例clear在clearable点击时触发slot="append"输入框带后置元素 -->
      <el-row :gutter="20">
        <el-col :span="4"
          ><el-select v-model="queryInfo.case_type" @change="getList">
            <el-option label="全部" value="0"></el-option>
            <el-option
              v-for="item in options"
              :key="item.type_id"
              :label="item.caption"
              :value="item.type_id"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="5"
          ><el-input
            placeholder="请输入内容"
            v-model="queryInfo.keywords"
            clearable
            @clear="getList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getList"
            ></el-button> </el-input
        ></el-col>
        <el-col :span="4">
          <el-button
            type="primary"
            @click="
              dialogVisible = true
              dialogTitle = '添加'
              form = {}
            "
            >添加</el-button
          >
        </el-col>
      </el-row>
      <!-- 表格 -->
      <!-- stripe设置斑马纹表格border设置竖边框 -->
      <el-table :data="routerList" border stripe>
        <el-table-column
          prop="case_id"
          label="ID"
          width="50px"
        ></el-table-column>
        <el-table-column prop="thumb_img" label="略缩图">
          <template slot-scope="scope">
            <img
              :src="$store.state.publicUrl + scope.row.thumb_img"
              style="width:100%"
            />
          </template>
        </el-table-column>
        <el-table-column prop="title" label="标题"></el-table-column>
        <el-table-column prop="caption" label="类型"></el-table-column>
        <el-table-column
          prop="content"
          label="内容"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column prop="public_time" label="发布时间">
          <template slot-scope="scope">
            {{ common.formatDate(scope.row.public_time) }}
          </template>
        </el-table-column>
        <el-table-column prop="public_status" label="发布状态">
          <template slot-scope="scope">
            {{ digitToChineseText(scope.row.public_status) }}
          </template></el-table-column
        >
        <el-table-column prop="click_count" label="点击数"></el-table-column>
        <el-table-column prop="sort" label="排序"></el-table-column>
        <el-table-column prop="create_time" label="创建时间">
          <template slot-scope="scope">
            {{ common.formatDate(scope.row.create_time) }}
          </template>
        </el-table-column>
        <el-table-column prop="public_status" label="发布/下架" width="190px">
          <template slot-scope="scope">
            <el-radio-group
              v-model="scope.row.public_status"
              size="small"
              @input="showChanged(scope.row)"
            >
              <el-radio-button label="1">发布</el-radio-button>
              <el-radio-button label="2">草稿</el-radio-button>
              <el-radio-button label="3">下架</el-radio-button>
            </el-radio-group>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120px">
          <template slot-scope="scope">
            <el-tooltip
              effect="dark"
              content="编辑"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="
                  showEditDialog(scope.row)
                  dialogTitle = '编辑角色'
                "
              ></el-button>
            </el-tooltip>
            <el-tooltip
              effect="dark"
              content="删除"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="removeById(scope.row.case_id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[1, 2, 5, 10]"
        :page-size="queryInfo.pagenum"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 对话框 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="80%"
      @close="dialogClosed"
    >
      <el-form
        :model="form"
        ref="formRef"
        :rules="formRules"
        label-width="80px"
        size="mini"
      >
        <el-form-item label="标题" prop="title">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="case_type">
          <el-select v-model="form.case_type">
            <el-option
              v-for="item in options"
              :key="item.type_id"
              :label="item.caption"
              :value="item.type_id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发布状态" prop="public_status">
          <el-select v-model="form.public_status">
            <el-option label="已发布" value="1"></el-option>
            <el-option label="草稿" value="2"></el-option>
            <el-option label="已下架" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="原图" prop="thumb_img">
          <!-- <el-input v-model="form.thumb_img_url" disabled></el-input> -->
          <el-upload
            class="upload-demo"
            :action="$store.state.publicUrl + 'admin/cases/uploadFileImg.htm'"
            :headers="uploadHeaders"
            accept=".jpeg,.jpg,.gif,.png"
            multiple
            :on-change="handleChange"
            :on-success="handleSuccess"
            :file-list="fileList"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <!-- <div class="el-upload__tip" slot="tip" style="display: inline;">
              只能上传jpeg/jpg/gif/png文件
            </div> -->
          </el-upload>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input v-model="form.sort"></el-input>
        </el-form-item>
        <el-form-item label="内容" prop="content">
          <!-- <el-input v-model="form.content"></el-input> -->
          <quill-editor
            ref="myQuillEditor"
            v-model="form.content"
            class="edit"
          ></quill-editor>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addList" v-if="dialogTitle === '添加'"
          >确 定</el-button
        >
        <el-button type="primary" @click="editList" v-else>确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    const validateLogo = (rule, value, callback) => {
      if (!this.form.original_img) {
        callback(new Error('请上传原图'))
      } else {
        callback()
      }
    }
    const validateLogo2 = (rule, value, callback) => {
      if (!this.form.thumb_img) {
        callback(new Error('请上传略缩图'))
      } else {
        callback()
      }
    }
    return {
      // 获取用户列表的参数对象
      queryInfo: {
        keywords: '',
        case_type: '',
        // 当前页码
        page: 1,
        // 当前每页显示数据数量
        pagenum: 10
      },
      routerList: [],
      total: 0,
      // 控制对话框显示隐藏
      dialogVisible: false,
      form: {},
      dialogTitle: '',
      options: [],
      fileList: [],
      fileList2: [],
      uploadHeaders: {
        token: window.sessionStorage.getItem('token')
      },
      formRules: {
        title: [{ required: true, message: '请填写视频标题', trigger: 'blur' }],
        content: [
          { required: true, message: '请填写文章内容', trigger: 'blur' }
        ],
        case_type: [
          { required: true, message: '请选择类型', trigger: 'change' }
        ],
        original_img: [
          { required: true, validator: validateLogo, trigger: 'change' }
        ],
        thumb_img: [
          { required: true, validator: validateLogo2, trigger: 'change' }
        ],
        public_status: [
          { required: true, message: '请选择发布状态', trigger: 'change' }
        ]
      }
    }
  },
  created() {
    this.getTypeList()
    this.getList()
  },
  methods: {
    async getList() {
      const { data: res } = await this.$http.post(
        'admin/cases/selectList.htm',
        this.queryInfo
      )
      if (res.rep_code !== 10) {
        return this.$message.error('获取列表失败！')
      }
      this.routerList = res.data.list
      this.total = res.data.pageinfo.count
    },
    async getTypeList() {
      const { data: res } = await this.$http.post(
        'admin/cases/selectListXType.htm'
      )
      if (res.rep_code !== 10) {
        return this.$message.error('获取类型列表失败！')
      }
      this.options = res.data.list
    },
    // 监听每页显示数量改变
    handleSizeChange(newSize) {
      this.queryInfo.pagenum = newSize
      this.getList()
    },
    // 监听页码改变
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getList()
    },
    // 监听用户对话框关闭
    dialogClosed() {
      this.$refs.formRef.resetFields()
      this.fileList = []
      this.fileList2 = []
    },
    // 添加
    addList() {
      this.$refs.formRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post(
          'admin/cases/insertOne.htm',
          this.form
        )
        if (res.rep_code !== 10) return this.$message.error(res.rep_msg)
        this.$message.success('添加成功')
        this.dialogVisible = false
        this.getList()
      })
    },
    // 展示编辑对话框
    async showEditDialog(row) {
      const { data: res } = await this.$http.post('admin/cases/selectOne.htm', {
        case_id: row.case_id
      })
      if (res.rep_code !== 10) return this.$message.error('查询失败')
      this.form = res.data
      this.dialogVisible = true
    },
    // 编辑
    editList() {
      this.$refs.formRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post(
          'admin/cases/updateOne.htm',
          this.form
        )
        if (res.rep_code !== 10) return this.$message.error(res.rep_msg)
        this.$message.success('编辑成功')
        this.dialogVisible = false
        this.getList()
      })
    },
    // 删除
    async removeById(id) {
      const confirmResoult = await this.$confirm(
        '此操作将永久删除此数据, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)
      if (confirmResoult === 'confirm') {
        const { data: res } = await this.$http.post(
          'admin/cases/deleteOne.htm',
          {
            case_id: id
          }
        )
        if (res.rep_code !== 10) return this.$message.error(res.rep_msg)
        this.$message.success('删除成功')
        this.getList()
      }
    },
    digitToChineseText(status) {
      const statusArr = ['', '已发布', '草稿', '已下架']
      return statusArr[status]
    },
    handleChange(file, fileList) {
      // 保留最后一个文件
      this.fileList = [fileList[fileList.length - 1]]
    },
    async showChanged(row) {
      const { data: res } = await this.$http.post(
        'admin/cases/updatePublicStatus.htm',
        {
          case_id: row.case_id,
          public_status: row.public_status
        }
      )
      if (res.rep_code !== 10) return this.$message.error(res.rep_msg)
      this.$message.success('编辑成功')
      if (row.public_status === '1') {
        this.getList()
      }
    },
    handleChange2(file, fileList2) {
      // 保留最后一个文件
      this.fileList2 = [fileList2[fileList2.length - 1]]
    },
    handleSuccess(response, file, fileList) {
      // 处理上传成功后的逻辑
      this.form.thumb_img = response.data.thumb_img
      this.form.original_img = response.data.original_img
    },
    handleSuccess2(response, file, fileList) {
      // 处理上传成功后的逻辑
      this.form.original_img = response.data.original_img
    }
  }
}
</script>
