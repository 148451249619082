<template>
  <el-container class="home-container">
    <!-- 头部区域 -->
    <el-header>
      <div>
        <img
          src="../../public/logo.png"
          alt=""
          width="40px"
          style="vertical-align: middle;"
        />
        <span>武侯商业秘密后台管理系统</span>
      </div>
      <el-button type="primary" plain @click="logout">退出</el-button>
    </el-header>
    <!-- 页面主题区 -->
    <el-container>
      <!-- 侧边栏 -->
      <el-aside :width="isCollapse ? '64px' : '200px'">
        <div class="toggle-btn" @click="toggleCollapse">|||</div>
        <!-- 侧边栏菜单区域 -->
        <!-- unique-opened表示一直展示一个子菜单 -->
        <el-menu
          background-color="#ebf1f6"
          text-color="#606266"
          active-text-color="#409eff"
          unique-opened
          :collapse="isCollapse"
          :collapse-transition="false"
          router
          :default-active="$route.path"
        >
          <!-- 一级菜单 -->
          <!-- :key是vuejs根据 key 的变化顺序来重新排列元素，并且将始终移除/销毁 key 已经不存在的元素 -->
          <template v-for="(item, index) in menulist">
            <el-submenu
              v-if="item.children && item.children.length > 0"
              :index="item.privi_id + ''"
              :key="item.privi_id"
            >
              <template v-slot:title>
                <i :class="iconsObj[index]"></i>
                <span>{{ item.caption }}</span>
              </template>
              <!-- 二级菜单 -->
              <el-menu-item
                :index="'/' + subItem.directory"
                v-for="subItem in item.children"
                :key="subItem.privi_id"
                @click="saveNavState('/' + subItem.directory)"
              >
                <template v-slot:title>
                  <i class="el-icon-menu"></i>
                  <span>{{ subItem.caption }}</span>
                </template>
              </el-menu-item>
            </el-submenu>
            <!-- 没有子项目的菜单 -->
            <el-menu-item
              v-else
              :index="'/' + item.directory"
              :key="item.privi_id + 0"
              @click="saveNavState('/' + item.directory)"
            >
              <template v-slot:title>
                <i :class="iconsObj[index]"></i>
                <span>{{ item.caption }}</span>
              </template>
            </el-menu-item>
          </template>
        </el-menu>
      </el-aside>
      <!-- 右侧内容主体 -->
      <el-container>
        <el-main>
          <!-- 路由占位符 -->
          <router-view></router-view>
        </el-main>
        <!-- <el-footer
          >ICP备案证书号：粤ICP备17064524号-2<br />
          © 2003-2020 广州市迅氪科技技术有限公司旗下品牌网站
          版权所有，并保留所有权利。</el-footer
        > -->
      </el-container>
    </el-container>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      // 左侧菜单数据
      menulist: [],
      iconsObj: [
        'el-icon-s-custom',
        'el-icon-user-solid',
        'el-icon-s-data',
        'el-icon-video-camera-solid',
        'el-icon-s-order',
        'el-icon-s-comment',
        'el-icon-location',
        'el-icon-s-help',
        'el-icon-s-check',
        'el-icon-s-promotion',
        'el-icon-phone',
        ''
      ],
      menulistadr: [
        ['RoleList', 'PermissionsManagement', 'AdminList'],
        ['OnlineRiskAssessment', 'EnterpriseDiagnosisAppointment'],
        ['VideoList', 'FunScience/CategoryList'],
        ['LegalGuidelines', 'WorkGuideline/CategoryList'],
        ['OnlineCourses'],
        ['CaseList', 'TypicalCase/CategoryList'],
        ['LatestNews', 'LatestNews/CategoryList'],
        ['ContactUs']
      ],
      // 是否水平折叠收起菜单
      isCollapse: false
    }
  },
  computed: {
    filteredMenuList() {
      return this.menulist.filter(item => item.children.length > 0)
    }
  },

  created() {
    this.getMenuList()
    // this.activePath = window.sessionStorage.getItem('activePath')
  },
  methods: {
    async logout() {
      await this.$http.post('admin/login/adminLoginOut.htm')
      window.sessionStorage.clear()
      this.$router.push('/login')
    },
    async getMenuList() {
      const { data: res } = await this.$http.post('admin/index/left.htm')
      if (res.rep_code !== 10) return this.$message.error(res.rep_msg)
      this.menulist = res.data
    },
    // 切换菜单的折叠与展开
    toggleCollapse() {
      this.isCollapse = !this.isCollapse
    },
    saveNavState(activePath) {
      // window.sessionStorage.setItem('activePath', activePath)
    }
  }
}
</script>

<style lang="less" scoped>
.home-container {
  height: 100%;
  background-color: #ebf1f6;
  color: #606266;
}
.el-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #409eff;
  font-size: 20px;
  i {
    font-size: 50px;
    vertical-align: middle;
  }
  span {
    margin-left: 15px;
  }
}
.el-aside {
  .el-menu {
    border-right: 0;
  }
  .toggle-btn {
    background-color: #ebf1f6;
    font-size: 10px;
    line-height: 24px;
    color: #606266;
    text-align: center;
    letter-spacing: 0.2em;
    cursor: pointer;
  }
  .toggle-btn:hover {
    background-color: rgb(188, 193, 197);
  }
}
.el-main {
  background-color: #fff;
}
.el-submenu.is-active .el-submenu__title {
  background-color: rgba(245 248 251) !important;
}
.el-menu-item.is-active {
  background-color: rgb(245 248 251) !important;
}

// .el-footer {
//   text-align: center;
//   font-size: 14px;
//   color: #5a6a7a;
//   line-height: 25px;
//   padding: 15px;
//   height: auto !important;
// };
</style>
