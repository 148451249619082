<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>管理员管理</el-breadcrumb-item>
      <el-breadcrumb-item>管理员列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区 -->
    <el-card>
      <!-- 搜索与添加区域 -->
      <!-- :gutter表示间距:span表示占据比例clear在clearable点击时触发slot="append"输入框带后置元素 -->
      <el-row :gutter="20">
        <!-- <el-col :span="8"
          ><el-input
            placeholder="请输入内容"
            v-model="queryInfo.query"
            clearable
            @clear="getUserList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getUserList"
            ></el-button> </el-input
        ></el-col> -->
        <el-col :span="4">
          <el-button type="primary" @click="addDialogVisible = true"
            >添加管理员</el-button
          >
        </el-col>
      </el-row>
      <!-- 表格 -->
      <!-- stripe设置斑马纹表格border设置竖边框 -->
      <el-table :data="userList" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column prop="admin_name" label="管理员名称"></el-table-column>
        <el-table-column prop="role_id" label="角色id"></el-table-column>
        <el-table-column prop="phone" label="手机号码"></el-table-column>
        <el-table-column prop="email" label="邮箱地址"></el-table-column>
        <el-table-column prop="create_time" label="创建时间">
          <template slot-scope="scope">
            {{ common.formatDate(scope.row.create_time) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="last_login_ip"
          label="最后一次登录ip"
        ></el-table-column>
        <el-table-column prop="last_login_time" label="最后一次登录时间">
          <template slot-scope="scope">
            {{ common.formatDate(scope.row.last_login_time) }}
          </template>
        </el-table-column>

        <!-- <el-table-column prop="use_status" label="申请状态">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.mg_state"
              @change="userStateChanged(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column> -->
        <el-table-column label="操作" width="174px">
          <template slot-scope="scope">
            <el-tooltip
              effect="dark"
              content="编辑"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="showEditDialog(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              effect="dark"
              content="修改密码"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-lock"
                size="mini"
                @click="
                  passwordDialogVisible = true
                  passwordForm.admin_id = scope.row.admin_id
                "
              ></el-button>
            </el-tooltip>
            <el-tooltip
              effect="dark"
              content="删除"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="removeUserById(scope.row.admin_id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[1, 2, 5, 10]"
        :page-size="queryInfo.pagenum"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 添加管理员对话框 -->
    <el-dialog
      title="添加管理员"
      :visible.sync="addDialogVisible"
      width="500px"
      @close="addDialogClosed"
    >
      <!-- 内容主体区 -->
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="120px"
      >
        <el-form-item label="管理员名称" prop="admin_name">
          <el-input v-model="addForm.admin_name"></el-input>
        </el-form-item>
        <el-form-item label="角色" prop="role_id">
          <el-select v-model="addForm.role_id">
            <el-option
              v-for="item in rolesList"
              :label="item.caption"
              :value="item.role_id"
              :key="item.role_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号码" prop="phone">
          <el-input v-model="addForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="邮箱地址" prop="email">
          <el-input v-model="addForm.email"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="email">
          <el-input v-model="addForm.password"></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUser">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改用户对话框 -->
    <el-dialog
      title="修改管理员"
      :visible.sync="editDialogVisible"
      width="500px"
      @close="editDialogClosed"
    >
      <el-form
        :model="editForm"
        :rules="addFormRules"
        ref="editFormRef"
        label-width="120px"
      >
        <el-form-item label="管理员名称" prop="admin_name">
          <el-input v-model="editForm.admin_name"></el-input>
        </el-form-item>
        <el-form-item label="角色" prop="role_id">
          <el-select v-model="editForm.role_id">
            <el-option
              v-for="item in rolesList"
              :label="item.caption"
              :value="item.role_id"
              :key="item.role_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号码" prop="phone">
          <el-input v-model="editForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="邮箱地址" prop="email">
          <el-input v-model="editForm.email"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editUser">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改用户密码对话框 -->
    <el-dialog
      title="修改密码"
      :visible.sync="passwordDialogVisible"
      width="400px"
      @close="passwordDialogClosed"
    >
      <el-form
        :model="passwordForm"
        :rules="addFormRules"
        ref="passwordFormRef"
        label-width="60px"
      >
        <el-form-item label="密码" prop="password">
          <el-input v-model="passwordForm.password"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="passwordDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="passwordEdit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    var checkEmail = (rule, value, cb) => {
      const regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (regEmail.test(value)) return cb()
      cb(new Error('请输入合法的邮箱地址'))
    }
    var checkMobile = (rule, value, cb) => {
      const regMobile = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[189]))\d{8}$/
      if (regMobile.test(value)) return cb()
      cb(new Error('请输入合法的手机号'))
    }
    return {
      // 获取用户列表的参数对象
      queryInfo: {
        // 当前页码
        page: 1,
        // 当前每页显示数据数量
        pagenum: 10
      },
      userList: [],
      rolesList: [],
      total: 0,
      // 控制对话框显示隐藏
      addDialogVisible: false,
      editDialogVisible: false,
      passwordDialogVisible: false,
      // 添加用户表单数据
      addForm: {},
      // 添加表单验证规则
      addFormRules: {
        admin_name: [
          { required: true, message: '请输入管理员名称', trigger: 'blur' },
          {
            min: 3,
            max: 10,
            message: '名称的长度在3~10个字',
            trigger: 'blur'
          }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            min: 3,
            max: 10,
            message: '密码的长度在6~15个字',
            trigger: 'blur'
          }
        ],
        email: [
          { required: false, message: '请输入邮箱', trigger: 'blur' },
          { validator: checkEmail, trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入手机', trigger: 'blur' },
          { validator: checkMobile, trigger: 'blur' }
        ],
        role_id: [{ required: true, message: '请选择角色', trigger: 'blur' }]
      },
      // 查询到的用户对象
      editForm: {},
      passwordForm: { admin_id: '' }
    }
  },
  created() {
    this.getUserList()
    this.getrolesList()
  },
  methods: {
    async getUserList() {
      const { data: res } = await this.$http.post(
        'admin/admins/selectList.htm',
        this.queryInfo
      )
      if (res.rep_code !== 10) {
        return this.$message.error('获取管理员列表失败！')
      }
      this.userList = res.data.list
      this.total = res.data.pageinfo.count
    },
    // 监听每页显示数量改变
    handleSizeChange(newSize) {
      this.queryInfo.pagenum = newSize
      this.getUserList()
    },
    // 监听页码改变
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getUserList()
    },
    // 监听状态开关
    // async userStateChanged(userinfo) {
    //   const { data: res } = await this.$http.put(
    //     `users/${userinfo.id}/state/${userinfo.mg_state}`
    //   )
    //   if (res.meta.status !== 200) {
    //     userinfo.mg_state = !userinfo.mg_state
    //     return this.$message.error('更新用户状态失败！')
    //   }
    //   this.$message.success('更新用户状态成功！')
    // },
    // 监听用户对话框关闭
    addDialogClosed() {
      this.$refs.addFormRef.resetFields()
    },
    editDialogClosed() {
      this.$refs.editFormRef.resetFields()
    },
    passwordDialogClosed() {
      this.$refs.passwordFormRef.resetFields()
    },
    async getrolesList() {
      const { data: res } = await this.$http.post('admin/roles/selectList.htm')
      this.rolesList = res.data.list
    },
    // 添加新用户
    addUser() {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post(
          'admin/admins/insertOne.htm',
          this.addForm
        )
        if (res.rep_code !== 10) return this.$message.error(res.rep_msg)
        this.$message.success('添加成功')
        this.addDialogVisible = false
        this.getUserList()
      })
    },
    // 展示编辑用户对话框
    async showEditDialog(row) {
      const { data: res } = await this.$http.post(
        'admin/admins/selectOne.htm',
        { admin_id: row.admin_id }
      )
      if (res.rep_code !== 10) return this.$message.error('查询失败')
      this.editForm = res.data
      this.editDialogVisible = true
    },
    // 编辑用户
    editUser() {
      this.$refs.editFormRef.validate(async valid => {
        if (!valid) return
        this.editForm.password = ''
        const { data: res } = await this.$http.post(
          'admin/admins/updateOne.htm',
          this.editForm
        )
        if (res.rep_code !== 10) return this.$message.error(res.rep_msg)
        this.$message.success('编辑成功')
        this.editDialogVisible = false
        this.getUserList()
      })
    },
    // 密码修改
    passwordEdit() {
      this.$refs.passwordFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post(
          'admin/admins/updateOne.htm',
          this.passwordForm
        )
        if (res.rep_code !== 10) return this.$message.error(res.rep_msg)
        this.$message.success('修改成功')
        this.passwordDialogVisible = false
      })
    },
    // 删除管理员
    async removeUserById(id) {
      const confirmResoult = await this.$confirm(
        '此操作将永久删除该数据, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)
      if (confirmResoult === 'confirm') {
        const { data: res } = await this.$http.post(
          'admin/admins/deleteOne.htm',
          {
            admin_id: id
          }
        )
        if (res.rep_code !== 10) return this.$message.error(res.rep_msg)
        this.$message.success('删除成功')
        this.getUserList()
      }
    }
  }
}
</script>

<style lang="less" scoped></style>
