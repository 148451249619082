<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>轮播图管理</el-breadcrumb-item>
      <el-breadcrumb-item>图片列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区 -->
    <el-card>
      <!-- 搜索与添加区域 -->
      <!-- :gutter表示间距:span表示占据比例clear在clearable点击时触发slot="append"输入框带后置元素 -->
      <el-row :gutter="20">
        <el-col :span="4">
          <el-button
            type="primary"
            @click="
              dialogVisible = true
              dialogTitle = '添加'
              form = {}
            "
            >添加</el-button
          >
        </el-col>
      </el-row>
      <!-- 表格 -->
      <!-- stripe设置斑马纹表格border设置竖边框 -->
      <el-table :data="routerList" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column prop="img_src" label="略缩图">
          <template slot-scope="scope">
            <img
              :src="$store.state.publicUrl + scope.row.img_src"
              style="width:100px"
            />
          </template>
        </el-table-column>
        <el-table-column prop="title" label="标题"></el-table-column>
        <el-table-column prop="target_url" label="跳转链接"></el-table-column>
        <el-table-column prop="click_count" label="点击数"></el-table-column>
        <el-table-column label="操作" width="120px">
          <template slot-scope="scope">
            <el-tooltip
              effect="dark"
              content="编辑"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="
                  showEditDialog(scope.row)
                  dialogTitle = '编辑'
                "
              ></el-button>
            </el-tooltip>
            <el-tooltip
              effect="dark"
              content="删除"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="removeById(scope.row.img_id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 对话框 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="500px"
      @close="dialogClosed"
    >
      <el-form
        :model="form"
        ref="formRef"
        :rules="formRules"
        label-width="80px"
        size="mini"
      >
        <el-form-item label="标题" prop="title">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="跳转模块" prop="target_url">
          <el-select
            v-model="form.target_url"
            @change="selectTrigger(form.target_url)"
          >
            <el-option label="趣味科普" value="spiceDetails"></el-option>
            <el-option label="工作指引" value="work"></el-option>
            <el-option label="典型案例" value="case"></el-option>
            <el-option label="最新资讯" value="news"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="跳转文章" prop="target_id">
          <el-select v-model="form.target_id" @change="$forceUpdate()">
            <el-option
              v-for="item in formList"
              :key="item.target_id"
              :label="item.title"
              :value="item.target_id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="封面" prop="img_src">
          <el-input v-model="form.img_src" v-show="false"></el-input>
          <el-upload
            class="upload-demo"
            :action="$store.state.publicUrl + 'admin/adsimg/uploadFileImg.htm'"
            :headers="uploadHeaders"
            accept=".jpeg,.jpg,.gif,.png"
            multiple
            :on-change="handleChange"
            :on-success="handleSuccess"
            :file-list="fileList"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addList" v-if="dialogTitle === '添加'"
          >确 定</el-button
        >
        <el-button type="primary" @click="editList" v-else>确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    const validateLogo = (rule, value, callback) => {
      if (!this.form.img_src) {
        callback(new Error('请上传图片'))
      } else {
        callback()
      }
    }
    return {
      // 获取用户列表的参数对象
      queryInfo: {
        keywords: '',
        information_type: '',
        // 当前页码
        page: 1,
        // 当前每页显示数据数量
        pagenum: 10
      },
      routerList: [],
      total: 0,
      // 控制对话框显示隐藏
      dialogVisible: false,
      form: {},
      dialogTitle: '',
      options: [],
      fileList: [],
      formList: [],
      uploadHeaders: {
        token: window.sessionStorage.getItem('token')
      },
      formRules: {
        title: [{ required: true, message: '请填写视频标题', trigger: 'blur' }],
        target_url: [
          { required: true, message: '请填写跳转链接', trigger: 'blur' }
        ],
        img_src: [
          { required: true, validator: validateLogo, trigger: 'change' }
        ]
      }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      const { data: res } = await this.$http.post('admin/adsimg/selectList.htm')
      if (res.rep_code !== 10) {
        return this.$message.error('获取列表失败！')
      }
      this.routerList = res.data
    },
    // 监听用户对话框关闭
    dialogClosed() {
      this.$refs.formRef.resetFields()
      this.fileList = []
    },
    // 添加
    addList() {
      this.$refs.formRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post(
          'admin/adsimg/insertOne.htm',
          this.form
        )
        if (res.rep_code !== 10) return this.$message.error(res.rep_msg)
        this.$message.success('添加成功')
        this.dialogVisible = false
        this.getList()
      })
    },
    // 展示编辑对话框
    async showEditDialog(row) {
      const { data: res } = await this.$http.post(
        'admin/adsimg/selectOne.htm',
        {
          img_id: row.img_id
        }
      )
      if (res.rep_code !== 10) return this.$message.error('查询失败')
      this.form = res.data
      this.dialogVisible = true
    },
    // 编辑
    editList() {
      this.$refs.formRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post(
          'admin/adsimg/updateOne.htm',
          this.form
        )
        if (res.rep_code !== 10) return this.$message.error(res.rep_msg)
        this.$message.success('编辑成功')
        this.dialogVisible = false
        this.getList()
      })
    },
    // 删除
    async removeById(id) {
      const confirmResoult = await this.$confirm(
        '此操作将永久删除此数据, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)
      if (confirmResoult === 'confirm') {
        const { data: res } = await this.$http.post(
          'admin/adsimg/deleteOne.htm',
          {
            img_id: id
          }
        )
        if (res.rep_code !== 10) return this.$message.error(res.rep_msg)
        this.$message.success('删除成功')
        this.getList()
      }
    },
    handleChange(file, fileList) {
      // 保留最后一个文件
      this.fileList = [fileList[fileList.length - 1]]
    },
    handleSuccess(response, file, fileList) {
      // 处理上传成功后的逻辑
      // this.form.thumb_img = response.data.thumb_img
      this.form.img_src = response.data.original_img
    },
    async selectTrigger(value) {
      let link = ''
      let name = ''
      if (value === 'spiceDetails') {
        link = 'admin/videos/selectList.htm'
        name = 'video_id'
      } else if (value === 'work') {
        link = 'admin/guides/selectList.htm'
        name = 'guide_id'
      } else if (value === 'case') {
        link = 'admin/cases/selectList.htm'
        name = 'case_id'
      } else {
        link = 'admin/information/selectList.htm'
        name = 'information_id'
      }
      const { data: res } = await this.$http.post(link, { public_status: 1 })
      if (res.rep_code !== 10) {
        return this.$message.error('获取列表失败！')
      }
      let str = ''
      str = JSON.stringify(res.data.list).replaceAll(name, 'target_id')
      this.form.target_id = JSON.parse(str)[0].target_id
      this.formList = JSON.parse(str)
    }
  }
}
</script>
