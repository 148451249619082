<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>联系我们</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区 -->
    <el-card>
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="电话号码">
          <el-input v-model="form.mobile"></el-input>
        </el-form-item>
        <el-form-item label="公司地址">
          <el-input v-model="form.address"></el-input>
        </el-form-item>
        <el-form-item label="公司邮箱">
          <el-input v-model="form.email"></el-input>
        </el-form-item>
        <el-form-item label="工作时间">
          <el-input v-model="form.working_time"></el-input>
        </el-form-item>
        <el-form-item label="平台介绍">
          <el-input type="textarea" v-model="form.introduction"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">保存</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
      total: 0,
      // 控制对话框显示隐藏
      dialogVisible: false,
      roleForm: {},
      dialogTitle: ''
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      const { data: res } = await this.$http.post('admin/us/selectOne.htm')
      if (res.rep_code !== 10) {
        return this.$message.error('获取信息失败！')
      }
      this.form = res.data
    },
    async onSubmit() {
      const { data: res } = await this.$http.post(
        'admin/us/saveOne.htm',
        this.form
      )
      if (res.rep_code !== 10) return this.$message.error(res.rep_msg)
      this.$message.success('保存成功')
    }
  }
}
</script>

<style lang="less" scoped></style>
