<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>快速维权</el-breadcrumb-item>
    </el-breadcrumb>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="维权列表" name="first"></el-tab-pane>
      <el-tab-pane label="资料清单" name="second"></el-tab-pane>
    </el-tabs>
    <!-- 卡片视图区 -->
    <el-card v-if="activeName == `first`">
      <!-- 搜索与添加区域 -->
      <!-- :gutter表示间距:span表示占据比例clear在clearable点击时触发slot="append"输入框带后置元素 -->
      <!-- 表格 -->
      <!-- stripe设置斑马纹表格border设置竖边框 -->
      <el-table :data="routerList" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column prop="title" label="标题"></el-table-column>
        <el-table-column prop="description" label="描述"></el-table-column>
        <el-table-column prop="username" label="用户名"></el-table-column>
        <el-table-column prop="safeguard_status" label="维权状态">
          <template slot-scope="scope">
            {{
              scope.row.safeguard_status == '1'
                ? '已提交'
                : scope.row.safeguard_status == '2'
                ? '处理中'
                : '已处理'
            }}
          </template>
        </el-table-column>

        <el-table-column prop="admin_name" label="处理管理员">
          <template slot-scope="scope">
            {{ scope.row.admin_name ? '' : scope.row.admin_name }}
          </template>
        </el-table-column>
        <el-table-column prop="handle_time" label="处理时间">
          <template slot-scope="scope">
            {{
              scope.row.handle_time == '0'
                ? ''
                : common.formatDate(scope.row.handle_time)
            }}
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="创建时间">
          <template slot-scope="scope">
            {{ common.formatDate(scope.row.create_time) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="174px">
          <template slot-scope="scope">
            <el-tooltip
              effect="dark"
              content="详情"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-s-fold"
                size="mini"
                @click="showEditDialog3(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              effect="dark"
              content="反馈"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-s-comment"
                size="mini"
                @click="
                  showEditDialog(scope.row)
                  dialogTitle = '反馈'
                "
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[1, 2, 5, 10]"
        :page-size="queryInfo.pagenum"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <el-card v-else>
      <!-- 搜索与添加区域 -->
      <el-row :gutter="20">
        <el-col :span="4">
          <el-button
            type="primary"
            @click="
              dialogVisible2 = true
              dialogTitle = '新增'
              datumForm = {}
            "
            >新增</el-button
          >
        </el-col>
      </el-row>
      <!-- 表格 -->
      <!-- stripe设置斑马纹表格border设置竖边框 -->
      <el-table :data="datumList" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column prop="file_name" label="文件名称"></el-table-column>
        <el-table-column prop="description" label="描述信息"></el-table-column>
        <el-table-column prop="file_url" label="文件地址"></el-table-column>
        <el-table-column prop="create_time" label="创建时间">
          <template slot-scope="scope">
            {{ common.formatDate(scope.row.create_time) }}
          </template>
        </el-table-column>
        <el-table-column prop="is_show" label="是否要显示">
          <template slot-scope="scope">
            <el-tooltip
              :content="scope.row.is_show == 1 ? '是' : '否'"
              placement="top"
            >
              <el-switch
                active-value="1"
                inactive-value="0"
                v-model="scope.row.is_show"
                @change="showChanged(scope.row)"
              >
              </el-switch>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="174px">
          <template slot-scope="scope">
            <el-tooltip
              effect="dark"
              content="编辑"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="
                  showEditDialog2(scope.row)
                  dialogTitle = '编辑'
                "
              ></el-button>
            </el-tooltip>
            <el-tooltip
              effect="dark"
              content="删除"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="removeDatumById(scope.row.list_id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange2"
        @current-change="handleCurrentChange2"
        :current-page="queryInfo2.page"
        :page-sizes="[1, 2, 5, 10]"
        :page-size="queryInfo2.pagenum"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 对话框 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="400px"
      @close="dialogClosed"
    >
      <el-form
        :model="roleForm"
        :rules="formRules"
        ref="formRef"
        label-width="80px"
      >
        <el-form-item label="维权状态" prop="safeguard_status">
          <el-select v-model="roleForm.safeguard_status">
            <el-option label="已提交" value="1"></el-option>
            <el-option label="处理中" value="2"></el-option>
            <el-option label="已处理" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="反馈内容" prop="feedback">
          <el-input v-model="roleForm.feedback" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editRole">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible2"
      width="400px"
      @close="dialogClosed"
    >
      <el-form
        :model="datumForm"
        ref="formRef"
        label-width="80px"
        :rules="formRules"
      >
        <el-upload
          class="upload-demo"
          drag
          :action="
            $store.state.publicUrl + 'admin/safeguard/uploadFileText.htm'
          "
          :headers="uploadHeaders"
          accept=".doc,.docx,.pdf,.xlsx,.xls,.csv,.txt"
          multiple
          :on-change="handleChange"
          :on-success="handleSuccess"
          :file-list="fileList"
          style="margin-bottom: 10px;"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">
            只能上传doc/docx/pdf/xlsx/xls/csv/txt文件,限制上传一个文件
          </div>
        </el-upload>
        <el-form-item label="文件名" prop="file_name">
          <el-input v-model="datumForm.file_name"></el-input>
        </el-form-item>
        <el-form-item label="文件描述" prop="description">
          <el-input v-model="datumForm.description"></el-input>
        </el-form-item>
        <el-form-item label="是否显示">
          <el-radio-group v-model="datumForm.is_show">
            <el-radio label="1">是</el-radio>
            <el-radio label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button
          type="primary"
          @click="addDatum"
          v-if="dialogTitle === '新增'"
          >确 定</el-button
        >
        <el-button type="primary" @click="editDatum" v-else>确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="维权详情"
      :visible.sync="dialogVisible3"
      width="650px"
      @close="dialogClosed"
    >
      <el-descriptions :column="2" border :data="form">
        <el-descriptions-item>
          <template slot="label">
            标题
          </template>
          {{ form.title }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">描述 </template>
          {{ form.description }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            用户名
          </template>
          {{ form.username }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            维权状态
          </template>
          {{
            form.safeguard_status == '1'
              ? '已提交'
              : form.safeguard_status == '2'
              ? '处理中'
              : '已处理'
          }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            处理管理员
          </template>
          {{ form.admin_name }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            反馈
          </template>
          {{ form.feedback }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            处理时间
          </template>
          {{ common.formatDate(form.handle_time) }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            创建时间
          </template>
          {{ common.formatDate(form.create_time) }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            取消时间
          </template>
          {{ common.formatDate(form.cancel_time) }}
        </el-descriptions-item>
        <el-descriptions-item
          v-if="form.file_urls != undefined"
          :contentStyle="{ width: '250px' }"
        >
          <template slot="label">
            文件链接列表
          </template>
          <el-link
            type="primary"
            v-for="(item, index) in JSON.parse(form.file_urls)"
            v-bind:key="index"
            :href="$store.state.publicUrl + item"
            target="_blank"
            >{{ item }}</el-link
          >
        </el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    const validateLogo = (rule, value, callback) => {
      if (!this.datumForm.file_url) {
        callback(new Error('请上传文件'))
      } else {
        callback()
      }
    }
    return {
      // 获取用户列表的参数对象
      queryInfo: {
        // 当前页码
        page: 1,
        // 当前每页显示数据数量
        pagenum: 10
      },
      queryInfo2: {
        // 当前页码
        page: 1,
        // 当前每页显示数据数量
        pagenum: 10
      },
      routerList: [],
      datumList: [],
      datumForm: {},
      fileList: [],
      formRules: {
        caption: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        privileges: [
          { required: true, message: '请输入权限Id', trigger: 'blur' }
        ],
        description: [
          { required: true, message: '请填写文件描述', trigger: 'blur' }
        ],
        file_url: [
          { required: true, validator: validateLogo, trigger: 'change' }
        ],
        file_name: [
          { required: true, message: '请填写文件名', trigger: 'blur' }
        ]
      },
      activeName: 'first',
      total: 0,
      // 控制对话框显示隐藏
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      roleForm: {},
      form: {},
      dialogTitle: '',
      uploadHeaders: {
        token: window.sessionStorage.getItem('token')
      }
    }
  },
  created() {
    this.getRoleList()
  },
  methods: {
    handleClick(tab, event) {
      this.activeName = tab.name
      if (this.activeName === 'first') {
        this.getRoleList()
      } else {
        this.getDatumList()
      }
    },
    async getRoleList() {
      const { data: res } = await this.$http.post(
        'admin/safeguard/selectList.htm',
        this.queryInfo
      )
      if (res.rep_code !== 10) {
        return this.$message.error('获取列表失败！')
      }
      this.routerList = res.data.list
      this.total = res.data.pageinfo.count
    },
    async getDatumList() {
      const { data: res } = await this.$http.post(
        'admin/safeguard/selectListRelist.htm',
        this.queryInfo2
      )
      if (res.rep_code !== 10) {
        return this.$message.error('获取列表失败！')
      }
      this.datumList = res.data.list
      this.total = res.data.pageinfo.count
    },
    // 监听每页显示数量改变
    handleSizeChange(newSize) {
      this.queryInfo.pagenum = newSize
      this.getRoleList()
    },
    handleSizeChange2(newSize) {
      this.queryInfo2.pagenum = newSize
      this.getDatumList()
    },
    // 监听页码改变
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getRoleList()
    },
    handleCurrentChange2(newPage) {
      this.queryInfo2.page = newPage
      this.getDatumList()
    },
    // 监听是否显示
    async showChanged(row) {
      const { data: res } = await this.$http.post(
        'admin/safeguard/updateOneRelist.htm',
        row
      )
      if (res.rep_code !== 10) return this.$message.error(res.rep_msg)
      this.$message.success('编辑成功')
    },
    // 监听用户对话框关闭
    dialogClosed() {
      this.$refs.formRef.resetFields()
    },
    // 添加
    addDatum() {
      this.$refs.formRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post(
          'admin/safeguard/insertOneRelist.htm',
          this.datumForm
        )
        if (res.rep_code !== 10) return this.$message.error(res.rep_msg)
        this.$message.success('添加成功')
        this.dialogVisible2 = false
        this.getDatumList()
      })
    },
    // 展示编辑对话框
    async showEditDialog(row) {
      const { data: res } = await this.$http.post(
        'admin/safeguard/selectOne.htm',
        {
          safeguard_id: row.safeguard_id
        }
      )
      if (res.rep_code !== 10) return this.$message.error('查询失败')
      this.roleForm = res.data
      this.dialogVisible = true
    },
    async showEditDialog2(row) {
      const { data: res } = await this.$http.post(
        'admin/safeguard/selectOneRelist.htm',
        {
          list_id: row.list_id
        }
      )
      if (res.rep_code !== 10) return this.$message.error('查询失败')
      this.datumForm = res.data
      this.dialogVisible2 = true
    },
    async showEditDialog3(row) {
      const { data: res } = await this.$http.post(
        'admin/safeguard/selectOne.htm',
        {
          safeguard_id: row.safeguard_id
        }
      )
      if (res.rep_code !== 10) return this.$message.error('查询失败')
      this.form = res.data
      this.dialogVisible3 = true
    },
    // 编辑
    editRole() {
      this.$refs.formRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post(
          'admin/safeguard/updateSafeguardStatus.htm',
          this.roleForm
        )
        if (res.rep_code !== 10) return this.$message.error(res.rep_msg)
        this.$message.success('编辑成功')
        this.dialogVisible = false
        this.getRoleList()
      })
    },
    editDatum() {
      this.$refs.formRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post(
          'admin/safeguard/updateOneRelist.htm',
          this.datumForm
        )
        if (res.rep_code !== 10) return this.$message.error(res.rep_msg)
        this.$message.success('编辑成功')
        this.dialogVisible2 = false
        this.getDatumList()
      })
    },
    // 删除
    async removeDatumById(id) {
      const confirmResoult = await this.$confirm(
        '此操作将永久删除该数据, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)
      if (confirmResoult === 'confirm') {
        const { data: res } = await this.$http.post(
          'admin/safeguard/deleteOneRelist.htm',
          {
            list_id: id
          }
        )
        if (res.rep_code !== 10) return this.$message.error(res.rep_msg)
        this.$message.success('删除成功')
        this.getDatumList()
      }
    },
    handleChange(file, fileList) {
      // 保留最后一个文件
      this.fileList = [fileList[fileList.length - 1]]
    },
    handleSuccess(response, file, fileList) {
      // 处理上传成功后的逻辑
      this.datumForm.file_url = response.data.file_url
      this.datumForm.file_name = file.name
    }
  }
}
</script>

<style lang="less" scoped></style>
