<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>专家咨询</el-breadcrumb-item>
      <el-breadcrumb-item>咨询列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区 -->
    <el-card>
      <!-- 搜索与添加区域 -->
      <el-row :gutter="20">
        <el-col :span="4"
          ><el-select
            v-model="queryInfo.consultation_status"
            @change="getConsultList"
          >
            <el-option label="全部" value="-1"></el-option>
            <el-option label="待回复" value="0"></el-option>
            <el-option label="已回复" value="1"></el-option>
            <el-option label="已撤销" value="2"></el-option>
            <el-option label="已完成" value="3"></el-option>
          </el-select>
        </el-col>
        <el-col :span="5"
          ><el-input
            placeholder="请输入内容"
            v-model="queryInfo.keywords"
            clearable
            @clear="getConsultList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getConsultList"
            ></el-button> </el-input
        ></el-col>
      </el-row>
      <!-- 表格 -->
      <!-- stripe设置斑马纹表格border设置竖边框 -->
      <el-table :data="consultationList" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column prop="username" label="用户名"></el-table-column>
        <el-table-column prop="content" label="咨询内容"></el-table-column>
        <el-table-column prop="consultation_status" label="咨询状态">
          <template slot-scope="scope">
            {{ digitToChineseText(scope.row.consultation_status) }}
          </template></el-table-column
        >
        <el-table-column prop="create_time" label="创建时间">
          <template slot-scope="scope">
            {{ common.formatDate(scope.row.create_time) }}
          </template>
        </el-table-column>
        <el-table-column prop="complete_time" label="完成时间">
          <template slot-scope="scope">
            {{ common.formatDate(scope.row.complete_time) }}
          </template>
        </el-table-column>
        <el-table-column prop="cancel_time" label="取消时间">
          <template slot-scope="scope">
            {{ common.formatDate(scope.row.cancel_time) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="174px">
          <template slot-scope="scope">
            <el-tooltip
              effect="dark"
              content="详情"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-s-fold"
                size="mini"
                @click="showEditDialog(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              effect="dark"
              content="对话框"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-s-comment"
                size="mini"
                @click="showDialog(scope.row)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[1, 2, 5, 10]"
        :page-size="queryInfo.pagenum"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 对话框 -->
    <el-dialog
      title="咨询详情"
      :visible.sync="dialogVisible"
      width="650px"
      @close="dialogClosed"
    >
      <el-descriptions :column="2" border :data="form">
        <el-descriptions-item>
          <template slot="label">
            用户名
          </template>
          {{ form.username }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            联系电话
          </template>
          {{ form.contact_phone }}
        </el-descriptions-item>
        <!-- <el-descriptions-item>
          <template slot="label">
            咨询类型
          </template>
          {{ form.consultation_type }}
        </el-descriptions-item> -->
        <el-descriptions-item>
          <template slot="label">
            咨询状态
          </template>
          {{ digitToChineseText(form.consultation_status) }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            内容
          </template>
          {{ form.content }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            创建时间
          </template>
          {{ common.formatDate(form.create_time) }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            完成时间
          </template>
          {{ common.formatDate(form.complete_time) }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            取消时间
          </template>
          {{ common.formatDate(form.cancel_time) }}
        </el-descriptions-item>
        <el-descriptions-item
          v-if="form.file_urls != undefined"
          :contentStyle="{ width: '250px' }"
        >
          <template slot="label">
            文件链接列表
          </template>
          <el-link
            type="primary"
            v-for="(item, index) in JSON.parse(form.file_urls)"
            v-bind:key="index"
            :href="$store.state.publicUrl + item"
            target="_blank"
            >{{ item }}</el-link
          >
        </el-descriptions-item>
      </el-descriptions>
    </el-dialog>
    <el-dialog
      title="咨询历史对话"
      :visible.sync="dialogVisible2"
      width="700px"
      @close="dialogClosed"
      ><el-row :gutter="20">
        <el-col :span="10"
          ><el-input
            placeholder="请输入内容"
            v-model="queryInfo2.keywords"
            clearable
            @clear="getConsultList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getConsultList"
            ></el-button> </el-input
        ></el-col>
      </el-row>
      <el-table :data="myConsultationList" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column prop="dialogue_type" label="对话类型"
          ><template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.is_read == '1'" effect="dark"
              >问</el-tag
            >
            <el-tag type="success" size="mini" v-else effect="dark">答</el-tag>
          </template></el-table-column
        >
        <el-table-column prop="is_read" label="是否已读"
          ><template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.is_read == '1'">已读</el-tag>
            <el-tag type="warning" size="mini" v-else>未读</el-tag>
          </template></el-table-column
        >
        <el-table-column prop="content" label="内容"></el-table-column>
        <el-table-column prop="create_time" label="创建时间">
          <template slot-scope="scope">
            {{ common.formatDate(scope.row.create_time) }}
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange2"
        @current-change="handleCurrentChange2"
        :current-page="queryInfo2.page"
        :page-sizes="[1, 2, 5, 10]"
        :page-size="queryInfo2.pagenum"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total2"
      >
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button
          type="danger"
          @click="closeDatum"
          v-if="consultation_status == 0 || consultation_status == 1"
          >关闭咨询</el-button
        >
        <el-button
          type="primary"
          @click="innerVisible = true"
          v-if="consultation_status == 0 || consultation_status == 1"
          >回复</el-button
        >
      </span>
      <el-dialog
        width="30%"
        title="回复框"
        :visible.sync="innerVisible"
        append-to-body
      >
        <el-form :model="form2">
          <el-form-item label="内容">
            <el-input
              type="textarea"
              v-model="form2.content"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="innerVisible = false">取 消</el-button>
          <el-button type="primary" @click="editDatum">确认回复</el-button>
        </span>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 获取用户列表的参数对象
      queryInfo: {
        keywords: '',
        // 当前页码
        page: 1,
        // 当前每页显示数据数量
        pagenum: 10,
        consultation_status: ''
      },
      queryInfo2: {
        consultation_id: '',
        keywords: '',
        // 当前页码
        page: 1,
        // 当前每页显示数据数量
        pagenum: 10
      },
      consultationList: [],
      myConsultationList: [],
      total: 0,
      total2: 0,
      // 控制对话框显示隐藏
      dialogVisible: false,
      dialogVisible2: false,
      innerVisible: false,
      form: {},
      form2: {},
      consultation_status: ''
    }
  },
  created() {
    this.getConsultList()
  },
  methods: {
    async getConsultList() {
      const { data: res } = await this.$http.post(
        'admin/consultations/selectConsultationsList.htm',
        this.queryInfo
      )
      if (res.rep_code !== 10) {
        return this.$message.error('获取列表失败！')
      }
      this.consultationList = res.data.list
      this.total = res.data.pageinfo.count
    },
    async getMyConsultList() {
      const { data: res } = await this.$http.post(
        'admin/consultations/selectConsultationDialogues.htm',
        this.queryInfo2
      )
      if (res.rep_code !== 10) {
        return this.$message.error('获取列表失败！')
      }
      this.myConsultationList = res.data.list
      this.total2 = res.data.pageinfo.count
    },
    // 监听每页显示数量改变
    handleSizeChange(newSize) {
      this.queryInfo.pagenum = newSize
      this.getConsultList()
    },
    handleSizeChange2(newSize) {
      this.queryInfo2.pagenum = newSize
      this.getMyConsultList()
    },
    // 监听页码改变
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getConsultList()
    },
    handleCurrentChange2(newPage) {
      this.queryInfo2.page = newPage
      this.getMyConsultList()
    },
    // 监听用户对话框关闭
    dialogClosed() {
      this.$refs.formRef.resetFields()
    },
    // 展示对话框
    async showEditDialog(row) {
      const { data: res } = await this.$http.post(
        'admin/consultations/selectConsultationDetails.htm',
        {
          consultation_id: row.consultation_id
        }
      )
      if (res.rep_code !== 10) return this.$message.error('查询失败')
      this.form = res.data
      this.dialogVisible = true
    },
    async showDialog(row) {
      this.queryInfo2.consultation_id = row.consultation_id
      this.getMyConsultList()
      this.consultation_status = row.consultation_status
      this.dialogVisible2 = true
    },
    async closeDatum() {
      const { data: res } = await this.$http.post(
        'admin/consultations/completeConsultation.htm',
        {
          consultation_id: this.queryInfo2.consultation_id
        }
      )
      if (res.rep_code !== 10) return this.$message.error('关闭失败')
      this.dialogVisible2 = false
      this.$message.success('已关闭咨询对话')
    },
    async editDatum() {
      const { data: res } = await this.$http.post(
        'admin/consultations/consultationReply.htm',
        {
          consultation_id: this.queryInfo2.consultation_id,
          content: this.form2.content
        }
      )
      if (res.rep_code !== 10) return this.$message.error('回复失败')
      this.$message.success('已回复')
      this.innerVisible = false
      this.getMyConsultList()
      this.getConsultList()
    },
    digitToChineseText(status) {
      const statusArr = ['待回复', '已回复', '已撤销', '已完成']
      return statusArr[status]
    }
  }
}
</script>
