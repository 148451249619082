<template>
  <div class="login_container">
    <div class="bg_box">
      <div class="login_box">
        <div class="avatar_box" tabindex="-1">
          <img src="../assets/logo.png" alt="" />
        </div>
        <div class="login_right">
          <div class="title_box">
            <div class="name">武侯商业秘密控制台</div>
            <div class="ps">Welcome&nbsp;!</div>
          </div>
          <!-- 登录表单区域 -->
          <el-form
            class="login_form"
            ref="loginFormRef"
            :model="loginForm"
            :rules="loginFormRules"
            status-icon
          >
            <!-- 用户名区域 -->
            <el-form-item prop="admin_name">
              <el-input
                v-model="loginForm.admin_name"
                prefix-icon="iconfont icon-yonghu"
              ></el-input>
            </el-form-item>
            <!-- 密码区域 -->
            <el-form-item prop="password">
              <el-input
                type="password"
                v-model="loginForm.password"
                prefix-icon="iconfont icon-mima"
              ></el-input>
            </el-form-item>
            <el-form-item class="btns">
              <el-button type="primary" @click="login">登录</el-button>
              <el-button type="info" @click="resetLoginForm">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { JSEncrypt } from 'jsencrypt'
export default {
  data() {
    return {
      // 表单数据绑定对象
      loginForm: {
        admin_name: 'DHK',
        password: '111111'
      },
      // 表单验证规则对象
      loginFormRules: {
        admin_name: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 3, max: 10, message: '长度在 3 到 10 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 15, message: '长度在 6 到 15 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    resetLoginForm() {
      this.$refs.loginFormRef.resetFields()
    },
    login() {
      this.$refs.loginFormRef.validate(async valid => {
        if (!valid) return
        const key =
          'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCniOMkFu1gbjzy5NC+rG6w5R0cjz8vD/kTetsDJJnfm7vtVezW7fqB1uOMLcV2BEKtKMhRgFcIfJfyPzIFDaFGKk9VHiqa8vdvVsrbmuAwFhQbqL/vEMOA75g56COtkAZBGIzWXyh6y+2RD80c3i+BV0r124IMrNZNozM/p/kqJwIDAQAB'
        const encryptor = new JSEncrypt()
        encryptor.setPublicKey(key)
        const rsaAdminName = encryptor.encrypt(this.loginForm.admin_name)
        const rsaPassWord = encryptor.encrypt(this.loginForm.password)
        const {
          data: { data: res }
        } = await this.$http.post('admin/login/adminLogin.htm', {
          admin_name: rsaAdminName,
          password: rsaPassWord
        })
        if (res.success !== 1) return this.$message.error('登录失败')
        this.$message.success('登录成功')
        window.sessionStorage.setItem('token', res.token)
        console.log(this)
        this.$router.push('/home')
      })
    }
  }
}
</script>
<!-- scoped ：样式只在本文件中有效 -->
<style lang="less" scoped>
.login_container {
  height: 100%;
  background-color: rgb(84 96 120);
  background-image: url('../assets/bg.jpg');
  background-size: auto no-repeat;
}

.login_box {
  width: 750px;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  .avatar_box {
    float: left;
    line-height: 360px;
    width: 350px;
    background-color: rgba(114, 151, 209, 0.3);
    img {
      widows: 100px;
      height: 100px;
    }
  }
  .login_right {
    float: left;
    width: 300px;
    padding: 30px;
    background-color: #fff;
  }
}
.bg_box {
  background-color: rgba(84, 96, 120, 0.6);
  height: 100%;
  text-align: center;
  width: 100%;
  .name {
    display: block;
    padding-top: 30px;
    color: hsl(231.82deg 36.67% 76.47%);
    font-size: 33px;
  }
  .ps {
    display: block;
    color: #ddd;
  }
}
.login_form {
  width: 300px;
  padding: 20px 20px 0;
  box-sizing: border-box;
}
.btns {
  text-align: right;
}
</style>
