<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>管理员管理</el-breadcrumb-item>
      <el-breadcrumb-item>角色列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区 -->
    <el-card>
      <!-- 搜索与添加区域 -->
      <!-- :gutter表示间距:span表示占据比例clear在clearable点击时触发slot="append"输入框带后置元素 -->
      <el-row :gutter="20">
        <el-col :span="4">
          <el-button
            type="primary"
            @click="
              dialogVisible = true
              dialogTitle = '添加角色'
              roleForm = {}
            "
            >添加角色</el-button
          >
        </el-col>
      </el-row>
      <!-- 表格 -->
      <!-- stripe设置斑马纹表格border设置竖边框 -->
      <el-table :data="routerList" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column prop="caption" label="标题"></el-table-column>
        <!-- <el-table-column prop="privi_id" label="权限"></el-table-column> -->
        <el-table-column label="操作" width="174px">
          <template slot-scope="scope">
            <el-tooltip
              effect="dark"
              content="编辑"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="
                  showEditDialog(scope.row)
                  dialogTitle = '编辑角色'
                "
              ></el-button>
            </el-tooltip>
            <el-tooltip
              effect="dark"
              content="删除"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="removeRoleById(scope.row.role_id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[1, 2, 5, 10]"
        :page-size="queryInfo.pagenum"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 对话框 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="400px"
      @close="dialogClosed"
    >
      <el-form
        :model="roleForm"
        :rules="formRules"
        ref="formRef"
        label-width="80px"
      >
        <el-form-item label="标题" prop="caption">
          <el-input v-model="roleForm.caption"></el-input>
        </el-form-item>
        <el-form-item label="权限ID" prop="privileges">
          <el-input v-model="roleForm.privileges"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="addRole"
          v-if="dialogTitle === '添加角色'"
          >确 定</el-button
        >
        <el-button type="primary" @click="editRole" v-else>确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 获取用户列表的参数对象
      queryInfo: {
        // 当前页码
        page: 1,
        // 当前每页显示数据数量
        pagenum: 10
      },
      routerList: [],
      formRules: {
        caption: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        privileges: [
          { required: true, message: '请输入权限Id', trigger: 'blur' }
        ]
      },
      total: 0,
      // 控制对话框显示隐藏
      dialogVisible: false,
      roleForm: {},
      dialogTitle: ''
    }
  },
  created() {
    this.getRoleList()
  },
  methods: {
    async getRoleList() {
      const { data: res } = await this.$http.post(
        'admin/roles/selectList.htm',
        this.queryInfo
      )
      if (res.rep_code !== 10) {
        return this.$message.error('获取角色列表失败！')
      }
      this.routerList = res.data.list
      this.total = res.data.pageinfo.count
    },
    // 监听每页显示数量改变
    handleSizeChange(newSize) {
      this.queryInfo.pagenum = newSize
      this.getRoleList()
    },
    // 监听页码改变
    handleCurrentChange(newPage) {
      this.queryInfo.pagenum = newPage
      this.getRoleList()
    },
    // 监听用户对话框关闭
    dialogClosed() {
      this.$refs.formRef.resetFields()
    },
    // 添加
    addRole() {
      this.$refs.formRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post(
          'admin/roles/insertOne.htm',
          this.roleForm
        )
        if (res.rep_code !== 10) return this.$message.error(res.rep_msg)
        this.$message.success('添加成功')
        this.dialogVisible = false
        this.getRoleList()
      })
    },
    // 展示编辑对话框
    async showEditDialog(row) {
      const { data: res } = await this.$http.post('admin/roles/selectOne.htm', {
        role_id: row.role_id
      })
      if (res.rep_code !== 10) return this.$message.error('查询失败')
      this.roleForm = res.data
      this.dialogVisible = true
    },
    // 编辑
    editRole() {
      this.$refs.formRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post(
          'admin/roles/updateOne.htm',
          this.roleForm
        )
        if (res.rep_code !== 10) return this.$message.error(res.rep_msg)
        this.$message.success('编辑成功')
        this.dialogVisible = false
        this.getRoleList()
      })
    },
    // 删除
    async removeRoleById(id) {
      const confirmResoult = await this.$confirm(
        '此操作将永久删除该数据, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)
      if (confirmResoult === 'confirm') {
        const { data: res } = await this.$http.post(
          'admin/roles/deleteOne.htm',
          {
            role_id: id
          }
        )
        if (res.rep_code !== 10) return this.$message.error(res.rep_msg)
        this.$message.success('删除成功')
        this.getRoleList()
      }
    }
  }
}
</script>

<style lang="less" scoped></style>
